$("#riskCat").on("click", function () {
    $("input[name='risk_category']").val($(this).val());
    getrisksSpecific($(this).val());

});

$('#addRiskSpecific #submitButton').click( function(e) {
    e.preventDefault();
    console.log("button clicked");
    console.log($('#addRiskSpecific form#submitForm').serialize());
    $.ajax({
        url: 'savetest',
        type: 'post',
        data: $('#addRiskSpecific form#submitForm').serialize(),
        success: function(data) {
            console.log(data);
            console.log(data['codrisc']);
            getrisksSpecific(data['codrisc']);
            $('form#submitForm').find("input[type=text], textarea").val("");
            $('form#submitForm').find("input[type=radio]").prop('checked', false);
            $('#addRiskSpecific').modal('toggle');
        }
    });
});


function moveItems(textarea, dest, codrisc, textareaName,codriskDB) {
    // console.log('moveItems');
    // console.log(textarea);
    // console.log(dest);
    // console.log(codrisc);
    // console.log(textareaName);
    // console.log(codriskDB);
    let lastChar = $("#sbTwo tbody tr").length;
    // console.log( $(textarea).find(':selected').html());
    // let lastChar = textareaName.slice(textareaName.length -1);
    // let value = $(textarea).find(':selected').html();
    let value = '<tr>';
    value += '<td>' +
                '<a class="right"><i class="fa fa-angle-double-left"></i></a>' +
                '<input type="text" class="form-control hidden" name="risks_codrisk_'+ lastChar +'" value="'+ codriskDB +'" />' +
                '<textarea name="risks_masure_'+ lastChar + '" rows="2" style="width: 100%">' + textarea + '</textarea>' +
                // '<textarea name="' + textareaName.replace('risk_', "risks_") + '" rows="2" style="width: 100%">' + textarea + '</textarea>' +
            '</td>';
    value += '<td><input type="text" class="form-control gravity" name="risks_gravity_'+ lastChar +'" value="'+ codrisc +'" /></td>';
    value += '<td><input type="text" class="form-control probability" name="risks_probability_'+ lastChar +'" value="" /></td>';
    value += '<td><input type="text" class="form-control risk_result" name="risks_calc_'+ lastChar +'" value="" /></td>';
    value +=  '   </tr>';

    $(dest).append(value);
}

function moveAllItems(origin, dest) {
    $(origin).children().appendTo(dest);
}

$(document).on('click', '.probability', function (e) {
    $(this).addClass("propTemp");
    if($(this).val()){
        $('#addProbability').find("[name='probability'][value="+$(this).val()+"]").prop('checked',true);
    }else{
        $('#addProbability').find('input[type=radio]').prop('checked',false);
    }
    $('#addProbability').modal('show');
});
//When we click on save
$(document).on('click', '#addProbability #submitButton', function (e) {
    var probabililty = $('input[name=probability]:checked', '#addProbability').val();

    var risk =  $(".propTemp").closest("tr").find(".gravity").val();
    console.log("de aici incep");
    console.log(probabililty);
    console.log(risk);

    $result = calculateProbabilityShit(parseInt(risk), parseInt(probabililty));
    $(".propTemp").closest("tr").find('.risk_result').val($result);

    $(".propTemp").val(probabililty).removeClass("propTemp");

    $('#addProbability').modal('hide');
});
$(document).on('click', '.left', function (e) {
    e.preventDefault();
    var textarea = $(this).parent().find('textarea').val();
    var textareaName = $(this).parent().find('textarea').attr("name");
    var codrisc = $(this).parent().find('.riskValue').val();
    var json = $(this).parent().data("old");
    var codriskDB = json.codrisc;
    // console.log('json');
    // console.log(json);
    // console.log(json.denriscs1);
    // console.log(json.natura);
    // console.log(json.codclasag);
    //
    // console.log(textarea);
    // console.log(codrisc);
    console.log(textareaName);
    moveItems(textarea, '#sbTwo tbody',codrisc,textareaName,codriskDB);
});

//Delete from list
$(document).on('click', '.fa.fa-angle-double-left', function (e) {
    console.log("intru pe right");
    e.preventDefault();
    $(this).closest("tr").remove();
});

$(document).on('click', '.risk_edit', function (e) {
    var json = $(this).parent().data("old");
    // console.log(json);
    // console.log(json.denriscs1);
    // console.log(json.natura);
    var natura = json.natura.split(',');
    // console.log(natura);
    $.each(natura, function( index, value ) {
        $('#addRiskSpecific').find("[name='natura[]'][value="+value+"]").prop('checked',true);
    });

    console.log(json.codclasag);
    console.log(" $('#addRiskSpecific').find(\"[name='risk_name']\").html(json.denriscs1)");
    $('#addRiskSpecific').find("[name='risk_id']").val(json.id);
    $('#addRiskSpecific').find("[name='risk_category']").val(json.codrisc);
    $('#addRiskSpecific').find("[name='risk_name']").val(json.denriscs1);
    $('#addRiskSpecific').find("[name='risk_masure']").val(json.denmas1);
    $('#addRiskSpecific').find("[name='risk_action']").val(json.action);

    $('#addRiskSpecific').find("[name='codclassag'][value="+json.codclasag+"]").prop('checked',true);
    $('#addRiskSpecific').find("[name='risk_raspundere_1']").val(json.raspunde1);
    $('#addRiskSpecific').find("[name='risk_termene_1']").val(json.termen1);
    $('#addRiskSpecific').find("[name='risk_raspundere_2']").val(json.raspunde2);
    $('#addRiskSpecific').find("[name='risk_termene_2']").val(json.termen2);
    $('#addRiskSpecific').find("[name='risk_raspundere_3']").val(json.raspunde3);
    $('#addRiskSpecific').find("[name='risk_termene_3']").val(json.termen3);
    $('#addRiskSpecific').modal('show');

    // moveItems(textarea, '#sbTwo tbody');
});

//Clear form after close model
$('#addRiskSpecific').on('hide.bs.modal', function(){
    clearInput($('#addRiskSpecific'));
});

function getrisksSpecific(id) {
    var html = '';
    $.post("risk_management/getRisks", {riskcat: id}, function (result) {
        console.log(result.data);
        for (var i = 0; i < result.data.length; i++) {
            html += '<div style="position: relative" data-old=\''+ JSON.stringify(result.data[i]) +'\'>' +
                '<input type="text" readonly value="' + result.data[i]['codriscs'] + '" style="float: left; width: 28px;" />'+
                '<textarea name="risk_masure_'+ i +'" rows="3" readonly>' + result.data[i]['denriscs1'] + '</textarea>' +
                '<a class="left"><i class="fa fa-angle-double-right"></i></a>\n' +
                '<a class="risk_edit"><i class="fa fa-edit"></i></a>\n' +
                '<input type="text" name="" class="riskValue" value="'+result.data[i]['codclasag']+'" readonly />\n' +
                '</div>';
            console.log(html);
        }
        $("#risk_specifics").html(html);
        $(".addNewRisk").removeClass("hidden");
    });
}

/*
    Calculate the Probability Risk
 */
function calculateProbabilityShit(risk, probabilty) {
    console.log(risk);
    console.log(probabilty);
    console.log(typeof risk);
    console.log(typeof probabilty);
    var result = 0;
    switch (true) {
        case risk === 1 && probabilty === 1:
        case risk === 1 && probabilty === 2:
        case risk === 1 && probabilty === 3:
        case risk === 1 && probabilty === 4:
        case risk === 1 && probabilty === 5:
        case risk === 1 && probabilty === 6:
        case risk === 2 && probabilty === 1:
            result = 1;
            break;
        case risk === 2 && probabilty === 2:
        case risk === 2 && probabilty === 3:
        case risk === 2 && probabilty === 4:
        case risk === 3 && probabilty === 1:
        case risk === 3 && probabilty === 2:
        case risk === 4 && probabilty === 1:
            result = 2;
            break;
        case risk === 2 && probabilty === 5:
        case risk === 2 && probabilty === 6:
        case risk === 3 && probabilty === 3:
        case risk === 3 && probabilty === 4:
        case risk === 4 && probabilty === 2:
        case risk === 5 && probabilty === 1:
        case risk === 6 && probabilty === 1:
        case risk === 7 && probabilty === 1:
            result = 3;
            break;
        case risk === 3 && probabilty === 5:
        case risk === 3 && probabilty === 6:
        case risk === 4 && probabilty === 3:
        case risk === 4 && probabilty === 4:
        case risk === 5 && probabilty === 2:
        case risk === 5 && probabilty === 3:
        case risk === 6 && probabilty === 2:
        case risk === 7 && probabilty === 2:
            result = 4;
            break;
        case risk === 4 && probabilty === 5:
        case risk === 4 && probabilty === 6:
        case risk === 5 && probabilty === 4:
        case risk === 5 && probabilty === 5:
        case risk === 6 && probabilty === 3:
        case risk === 7 && probabilty === 3:
            result = 5;
            break;
        case risk === 5 && probabilty === 6:
        case risk === 6 && probabilty === 4:
        case risk === 6 && probabilty === 5:
        case risk === 7 && probabilty === 4:
            result = 6;
            break;
            // (6,6) (7,5) (7,6)
        case risk === 6 && probabilty === 6:
        case risk === 7 && probabilty === 5:
        case risk === 7 && probabilty === 6:
            result = 7;
            break;
    }

    return result;
}

$("#clear_table").click(function () {
    $(this).parent().find('table tbody').empty();
});

clearInput = function (form) {
    $(form).find('input[type=text], input[type=password], input[type=number], input[type=email], textarea').val('');
    $(form).find('input[type=radio]').prop('checked',false);
    $(form).find('input[type=checkbox]').prop('checked',false);
};
