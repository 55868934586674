$(function(){
    if ($('.risk.user_test').length) {
        var loading = $('#loadbar').hide();
        $(document)
            .ajaxStart(function () {
                loading.show();
            }).ajaxStop(function () {
            loading.hide();
        });


        //Make tests random
        var makeTest1 = $("#makeTest1 .question");
        // console.log(makeTest1.length);
        for(var i = 0; i < makeTest1.length; i++){
            // console.log(i);
            var target = Math.floor(Math.random() * makeTest1.length -1) + 1;
            var target2 = Math.floor(Math.random() * makeTest1.length -1) +1;
            makeTest1.eq(target).before(makeTest1.eq(target2));
        }

        $("#makeTest1 .question").first().removeClass("hidden");


        var makeTest2 = $("#makeTest2 .question");
        for(var i = 0; i < makeTest2.length; i++){
            var target = Math.floor(Math.random() * makeTest2.length -1) + 1;
            var target2 = Math.floor(Math.random() * makeTest2.length -1) +1;
            makeTest2.eq(target).before(makeTest2.eq(target2));
        }

        $("#makeTest2 .question").first().removeClass("hidden");

        var makeTest3 = $("#makeTest3 .question");
        for(var i = 0; i < makeTest3.length; i++){
            var target = Math.floor(Math.random() * makeTest3.length -1) + 1;
            var target2 = Math.floor(Math.random() * makeTest3.length -1) +1;
            makeTest3.eq(target).before(makeTest3.eq(target2));
        }

        $("#makeTest3 .question").first().removeClass("hidden");


        window.questions = [];

        $("#formQuestion input").on('click', function (e) {
            // if($(this).find("input[type='radio']:checked")) { alert($(this).find('input:radio').attr("name")); }
            // debugger;
            // console.log("intru in label.btn");
            var parent = $(this).closest(".modal-dialog"),
                choice = $(this).val(),
                ans = $(this).closest(".question").find("h3 span").data("correct"),
                totalNumberOfQuestions = $(this).closest(".quiz").data("totalquestions"),
                answer = $(this).checking(choice, ans),
                buttonClose = $(this).closest(".modal").attr('id'),
                theEnd = !parent.find('#quiz .row div:last-child').hasClass('hidden'),
                totalPercent = 0;

            $(this).closest(".tab-pane").find('.wrongPercent').addClass("hidden");
            if(!questions[buttonClose]){
                questions[buttonClose] = []
            }
            // questions[buttonClose][] = answer;

            questions[buttonClose].push(answer);

            // console.log("totalNumberOfQuestions: " + totalNumberOfQuestions);
            // console.log("choice: " + choice);
            // console.log("ans: " + ans);
            // console.log("theEnd: " + theEnd);

            parent.find('#loadbar').show();

            var index = questions[buttonClose].length - 1;

            //console.log('index: ' + index);

            parent.find('#quiz .row div:eq('+ index +')').addClass("hidden");
            parent.find('#quiz .row div:eq('+ questions[buttonClose].length +')').removeClass("hidden");
            parent.find( "#answer" ).html( answer );

            parent.find('#loadbar').fadeOut();
//            console.log('theEnd; ' + theEnd);

            if(theEnd){
                parent.find(".questionResult").removeClass("hidden");
                parent.find(".btnClose").removeClass("hidden");
                let corect = 0;
                $.each(questions[buttonClose], function( index, value ) {
                    if(value === 'CORECT'){
                        corect += 1;
                    }
                });
                totalPercent = ((corect * 100) / totalNumberOfQuestions).toFixed(2);
                console.log("totalPercent: " + totalPercent);
                parent.find(".totalResult").html(corect);
                parent.find(".totalPercent").html(totalPercent);
                if(parseInt(totalPercent) < 70){
                    questions[buttonClose] = [];//Reset array with this key if we have under 70;
                    $(this).closest(".tab-pane").find('.wrongPercent').removeClass("hidden");
                }else{
                    $(this).closest(".tab-pane").prepend("<input type='hidden' name='"+buttonClose+"' value='"+totalPercent+"' />");
                    $(this).closest(".tab-pane").attr("data-totalPercent", totalPercent);

                    setTimeout(function() {$('.modal').modal('hide');}, 2000);
                }
                // console.log(questions);
            }


            // console.log("totalPercent: " + totalPercent);
            //
            // console.log('#' + buttonClose);
            // console.log(questions[buttonClose]);
            // return false;
        });

        $('.modal').on('hide.bs.modal', function (e) {
            // $(window).bind('beforeunload', function(){
            //     return "You want to leave this page?";
            // });

            // $('.question').addClass('hidden');
            $(this).find(".question").addClass('hidden');
            console.log("hide.bs.modal");
            let totalPercent = $(this).closest(".tab-pane").attr("data-totalpercent");
            // console.log(totalPercent);
            // console.log(typeof totalPercent);
            // console.log(parseInt(totalPercent));
            //console.log($("#formQuestion").serialize());

            if(parseInt(totalPercent) >= 70){
                $(".se-pre-con").fadeIn("slow");
                let currentTabId = $(this).closest(".tab-pane").attr('id');
                // console.log("currentTabId: " + currentTabId);
                // console.log("#"+currentTabId + "-tab");
                $("#"+currentTabId + "-tab").removeClass("active");
                $("#"+currentTabId + "-tab").addClass("disabledTab").next().removeClass('disabledTab').addClass('active');
                $("#"+currentTabId).removeClass("active").next().addClass("active");

                $.post('/admin/user_tests/save', $("#formQuestion").serialize(), function(response) {
                    console.log(response);
                    if(response.success == true){
                        let final = parseFloat(response.final_result);
                        $(".finalResult span").html(final.toFixed(2));
                        $("#formQuestion").trigger("reset");
                        let testNameTobeDeleted =  $("#formQuestion").find('input[type=hidden]');
                        // console.log('testNameTobeDeleted');
                        // console.log(testNameTobeDeleted);
                        testNameTobeDeleted.remove();
                    // }else{
                    //     alert("Eruare!");
                    }
                }).fail(function (XMLHttpRequest, textStatus, errorThrown){
                     alert('status:' + XMLHttpRequest.status + ', status text: ' + XMLHttpRequest.statusText);
                    // console.log(textStatus, errorThrown);
                    $.post('/admin/user_tests/saveerror', {'code':textStatus, 'error': errorThrown }, function(response) {
                        console.log('status:' + XMLHttpRequest.status + ', status text: ' + XMLHttpRequest.statusText);
                        console.log(textStatus, errorThrown);
                    })
                });
                $(".se-pre-con").fadeOut("slow");
            }else{
                let buttonClose = $(this).closest(".modal").attr('id');
                let totalNumberOfQuestions = $(this).closest(".quiz").data("totalquestions");
                let count = 0;
                // console.log(questions);
                // console.log(buttonClose);
                if(buttonClose){
                    // console.log(buttonClose);
                    // console.log(questions);
                    if(questions.length > 0){
                        count = questions[buttonClose].length;
                    }else{
                        count = 0;
                        questions[buttonClose] = [];
                    }
                }else{
                    count = questions[totalNumberOfQuestions].length;
                }
                // console.log("elase");
                // console.log(buttonClose);
                // console.log('count: ' + count);
                questions[buttonClose].splice(0,count);
                $(this).closest(".tab-pane").find(".questionResult").addClass("hidden");
                $(this).closest(".tab-pane").find('#quiz .row div:eq(0)').removeClass("hidden");

                // console.log(count);
                // console.log(questions[buttonClose]);
            }
        })

        $.fn.checking = function(ck, ans) {
           // return 'CORECT';//don't forget this!!
            if (ck != ans)
                return 'INCORECT';
            else
                return 'CORECT';
        };
    }
});
