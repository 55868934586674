window.evaluation = [];
$('#partner').on('change', function() {
    $(".se-pre-con").fadeIn("slow");
    console.log("intru pe partner");
    let documents;
    var cui = $(this).find(':selected').data("cui");
    // $.get("partner/get", {cui: cui}, function (result) {
    //     // console.log(typeof result.data);
    //     evaluation = result.data;
    //     documents = result.documents;
    //     // console.log("documents");
    //     // console.log(documents);
    //
    //     let documentsShow = '';
    //     $.each(documents, function( index, value ) {
    //         documentsShow += '<a href="/admin/documents/download/'+ cui +'/'+ value +'" class="btn btn-success text-left">' + value +'</a>';
    //     });
    //     // console.log("documentsShow");
    //     // console.log(documentsShow);
    //     $("#rapoarte .col-md-12").html(documentsShow);
    //
    // });
    // var name = $("#workplace_down option:selected").text();
    // var caen = $("#workplace_down option:selected").val();
   var workstations = $(this).find('option:selected').data("workstation");

   var caen = $(this).find('option:selected').data("caen");
   var name = $(this).find('option:selected').html();
   var id = $(this).find('option:selected').val();

   $(".editFirma").attr("href", '/admin/partners/'+id+'/edit');

    $("#partner_cui").val(cui);
    $("#partner_caen").val(caen);
    $("#partner_name").val(name);
    var workStationptions = '';
    $.each(workstations, function( index, value ) {
        workStationptions += '<option value="'+value.replaceAll('"', '&quot;')+'">'+value+'</option>';
    });

    $("#workstation").html(workStationptions);
    $(".se-pre-con").fadeOut("slow");
});

//Working with Workstation
$(document).on('click', '#workstation', function (e) {
    $(".se-pre-con").fadeIn("slow");
    let company = $("[name='config_partner']").find(':selected').val();


    console.log("intru #workstation");
    $("#evaluatori input").each(function (index, val) {
        if($(val).attr("name") !== 'config_use_admin'){
            $(val).val('');
        }
    });

    var workstation = $(this).find(':selected').val();
    if(workstation){
        workstation = $.trim(workstation);
    }

    var cui = $("#partner").find(':selected').data("cui");
    $.get("partner/get", {cui: cui, workstation: workstation}, function (result) {
        // console.log(typeof result.data);
        evaluation = result.data;
        documents = result.documents;
        // console.log("documents");
        // console.log(documents);

        let documentsShow = '';
        $.each(documents, function( index, value ) {
            // console.log(index, value);
            documentsShow += '<a href="/admin/documents/download/'+ cui +'/'+ value.replaceAll('"', '&quot;') +'" class="btn btn-success text-left">' + value.replaceAll('"', '&quot;') +'</a>';
        });
        // console.log("documentsShow");
        // console.log(documentsShow);
        $("#rapoarte .col-md-12").html(documentsShow);

        console.log('workstation: ' + workstation);
        console.log('companyiD: ' + company);

        $("#workspace").find('option').remove();
        $("#workstation_name").data("oldname",workstation);
        $("#workstation_name").val(workstation);
        let conclusions = null;
        // console.log('workstation');
        // console.log(workstation);
        // console.log(evaluation);
        // console.log(evaluation.length);
        // console.log(evaluation[$.trim(workstation)]);
        let generalPercent = null;
        let configuration = null;
        if(typeof evaluation[$.trim(workstation)] != 'undefined'){
            generalPercent = evaluation[$.trim(workstation)]['generalPercent'];
            configuration = evaluation[$.trim(workstation)]['configuration'];
            // console.log("configuration inside and before delete");
            // console.log(configuration);
            // delete evaluation[$.trim(workstation)]['configuration'];
            let generalValue = 0;
            let generalNumber = 0;
            $.each( evaluation[$.trim(workstation)], function( key, value ) {
                if(value.generalPercent){
                    generalValue += parseFloat(value.generalPercent);
                    generalNumber += 1;
                }
            });
            generalPercent = generalValue > 0 ? generalValue / generalNumber : generalPercent;
            generalPercent = generalPercent.toFixed(2)

            delete evaluation[$.trim(workstation)]['generalPercent'];
        }
        //
        // console.log("generalPercent");
        // console.log(generalPercent);

        if(configuration){
            $("#evaluatori input").each(function (index, val) {
                if($(val).attr("name") !== 'config_use_admin'){
                    $.each(configuration['config_evaluatori'] , function( i, v ) {
                        $(val).val(configuration['config_evaluatori'][index]);
                    });
                }
            });
            $("#echip_tehn input").each(function (index, val) {
                $.each(configuration['config_echip_tehn'] , function( i, v ) {
                    $(val).val(configuration['config_echip_tehn'][index]);
                });
            });

            $("input[name='config_timp_expunere']").val(configuration['config_timp_expunere']);
            $("input[name='config_nr_loc_de_munca']").val(configuration['config_nr_loc_de_munca']);
            // console.log('check');
            // console.log(configuration['config_use_admin']);
            if(configuration['config_use_admin']){
                $("input[name='config_use_admin']").prop( "checked", true );
            }
        }
        // console.log("inainte de each evaluation");
        // console.log(evaluation);
        $.each( evaluation, function( key, value ) {
            if(key === $.trim(workstation)){
                // console.log( key + ": " + value );
                $.each( evaluation[key], function( i, val ) {
                    // console.log("inside");
                    // console.log( key + ": " + val );
                    if(i !== 'conclusions' && i !== 'configuration'){
                        // console.log("de cate ori intru aici");
                        // console.log(i === 'configuration');
                        // console.log( i + ":1 " + val );
                        $("#workspace").append('<option value="'+i+'">'+evaluation[key][i]['cor_name']+'</option>');
                    }else{
                        // console.log("intru in concluzii");
                        conclusions = evaluation[key]['conclusions'];
                    }
                });
            }
        });

        if(typeof evaluation[$.trim(workstation)] != 'undefined') {
            var workspace = $("[name='config_workspace']").find(':selected').html();
            var textPie = ['Ponderea factorilor de risc identificaţi după elementele sistemului de muncă', 'Nivel global de risc: ' + generalPercent + ''];
            Chartjs.defaults.global.defaultFontSize = 20;
            var pieChart = new Chartjs(document.getElementById("pie-chart-general"), {
                type: 'pie',
                data: {
                    datasets: [{backgroundColor: [], data: []}],
                    labels: []
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                steps: 1,
                                stepValue: 6,
                                max: 7 //max value for the chart is 60
                            }
                        }]
                    },
                    title: {display: true, text: textPie}
                }
            });
            var newDataset = [{backgroundColor: [], data: []}];
            var labels = [];
            var colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#C0C0C0', '#808080', '#800000', '#808000', '#008000', '#800080', '#008080', '#000080'];
            // console.log('conclusions');
            // console.log(conclusions);
            $.each(conclusions, function (index, value) {
                if(index !== 'count'){
                    // console.error(index);
                    // console.error(value);
                    let randomColor = Math.floor(Math.random() * colors.length);
                    newDataset[0].data.push(value);
                    newDataset[0].backgroundColor.push(colors[randomColor]);
                    labels.push(index);
                }

            });
            // console.log('newDataset');
            // console.log(newDataset);
            pieChart.data.labels = labels;
            pieChart.data.datasets = newDataset;
            pieChart.update();
        }

        $("#rapoarte .col-md-12 a").addClass("hidden");

        $.each($("#rapoarte .col-md-12 a"), function( index, value ) {
            if (value.href.indexOf(encodeURI(workstation)) != -1) {
                $(value).removeClass("hidden")
            }
        });
    });

    $(".generate_evaluare").attr("href", "documents/evaluare/"+company+"/"+$.trim(workstation)+"/create");

    $(".se-pre-con").fadeOut("slow");
});


//Edit a WorkPlace
$('#workplace_down').on('change', function() {
    var name = $("#workplace_down option:selected").text();
    var caen = $("#workplace_down option:selected").val();

    $(".show-workplace-details input[name='show-workplace-details-id']").attr("readonly", true).val(caen);
    $(".show-workplace-details input[name='show-workplace-details-name']").val(name);
});

//Add or Edit a WorkPlace
$(document).on('click', '.addOrModidyCOR', function (e) {
    e.preventDefault();
    $(".se-pre-con").fadeIn("slow");

    var name = $(".show-workplace-details input[name='show-workplace-details-name']").val();
    var cod = $(".show-workplace-details input[name='show-workplace-details-id']").val();

    $.post('/admin/cor/save', {name: name, cod:cod}, function(response) {
        var workStationptions = '';
        if(response.success == true && response.data.length > 0){
            $("#workplace_down").empty();
            $.each(response.data, function( index, value ) {
                workStationptions += '<option value="'+value.cod+'">'+value.name+'</option>';
            });
            $("#workplace_down").html(workStationptions);
            $(".show-workplace-details input[name='show-workplace-details-name']").val("");
            $(".show-workplace-details input[name='show-workplace-details-id']").val("");


        }else{
            alert("Eruare!");
        }
        $(".se-pre-con").fadeOut("slow");
    });
});

//Copy a WorkPlace to list
$(document).on('click', '.selectWCOR', function (e) {
    e.preventDefault();
    var name = $(".show-workplace-details input[name='show-workplace-details-name']").val();
    var cod = $(".show-workplace-details input[name='show-workplace-details-id']").val();
    $("#workspace").append('<option value="'+cod+'">'+name+'</option>');
});

$(document).on('click', '#workspace', function (e) {
    console.log("aici intru in #workspace");
    $('#sbTwo tbody').empty();
    var workspace = $(this).find(':selected').val();
    var workstation = $('#workstation').find(':selected').val();
    if(workstation){
        workstation = $.trim(workstation);
    }
    var name = $(this).find(':selected').html();
    $(".showWorkspace").val(name);


    // console.log(workspace);
    // console.log(workstation);
    // console.log("dddre");
    // console.log(evaluation[workstation][workspace]);
    // console.log(evaluation[workstation][workspace][0]);
    // console.log(evaluation[workstation][workspace].length);

    if(evaluation[workstation] && evaluation[workstation][workspace]){
        let specificFactors = evaluation[workstation][workspace][0]['results']['specificFactors'];
        let workplaceDB = evaluation[workstation][workspace][0]['results']['workplace'];
        let configuration = evaluation[workstation][workspace][0]['results']['configuration'];
        let reportsX = evaluation[workstation][workspace]['raports']['x'];
        let generalPercent = evaluation[workstation][workspace]['generalPercent'];
        let detailsRiskCalc = evaluation[workstation][workspace]['detailsRiskCalc'];
        // console.log('configuration');
        // console.log(reportsX);
        // console.log('specificFactors');
        // console.log(specificFactors);
        if(workplaceDB){
            $("#work_process").val(workplaceDB['workplace_work_process']);
            $("#exec").val(workplaceDB['workplace_exec']);
            $("#job").val(workplaceDB['workplace_job']);
            $("#production_details").val(workplaceDB['workplace_production_details']);
            $("#environment").val(workplaceDB['workplace_environment']);
        }

        if(configuration){
            // $("#evaluatori input").each(function (index, val) {
            //     $.each(configuration['config_evaluatori'] , function( i, v ) {
            //         $(val).val(configuration['config_evaluatori'][index]);
            //     });
            //
            // });
            // $("#echip_tehn input").each(function (index, val) {
            //     $.each(configuration['config_echip_tehn'] , function( i, v ) {
            //         $(val).val(configuration['config_echip_tehn'][index]);
            //     });
            // });

            $("input[name='config_timp_expunere']").val(configuration['config_timp_expunere']);
            $("input[name='config_nr_loc_de_munca']").val(configuration['config_nr_loc_de_munca']);
        }

        let specificFactorsArr = [], specificFactorsByCodRisk = [];
        if(specificFactors){
            $.each(specificFactors , function( index, value ) {
                let codRisk = value['codrisk'];
                // specificFactorsByCodRisk.push({codRisk: value});

                // specificFactorsByCodRisk[value['codrisk']] = value;
                let html = '<tr>';
                html += '<td>' +
                        '<a class="right"><i class="fa fa-trash-alt"></i></a>' +
                        '<input type="text" class="form-control hidden" name="risks_codrisk_'+ index +'" value="'+ value['codrisk'] +'" />' +
                        '<textarea name="risks_masure_' + index + '" rows="2" style="width: 100%">' + value['masure'] + '</textarea>' +
                    '</td>';
                html += '<td><input type="text" class="form-control gravity" name="risks_gravity_'+ index +'" value="'+ value['gravity'] +'" /></td>';
                html += '<td><input type="text" class="form-control probability" name="risks_probability_'+ index +'" value="'+ value['probability'] +'" /></td>';
                html += '<td><input type="text" class="form-control risk_result" name="risks_calc_'+ index +'" value="'+ value['calc'] +'" /></td>';
                html +=  '   </tr>';

                $('#sbTwo tbody').append(html);


            });

            // console.log("specificFactorsByCodRisk");
            // console.log(specificFactorsByCodRisk);

            var workspace = $("[name='config_workspace']").find(':selected').html();
            var textPie = ['Ponderea factorilor de risc identificaţi după elementele sistemului de muncă', 'Locul de muncă : ' + workspace + '', 'Nivel global de risc: '+ generalPercent +''];
            Chartjs.defaults.global.defaultFontSize = 20;
            var pieChart = new Chartjs(document.getElementById("pie-chart"), {
                type: 'pie',
                data: {
                    datasets: [{ backgroundColor: [], data: [] }],
                    labels: []
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                steps: 1,
                                stepValue: 6,
                                max: 7 //max value for the chart is 60
                            }
                        }]
                    },
                    title: { display: true,  text: textPie }
                }
            });
            var newDataset = [{ backgroundColor: [],  data: [] }];
            var labels = [];
            var colors =  ["#3e95cd", "#8e5ea2","#3cba9f","#e8c3b9","#c45850",'#FF0000','#00FF00','#0000FF','#FFFF00','#00FFFF','#FF00FF','#C0C0C0','#808080','#800000','#808000','#008000','#800080','#008080','#000080'];

            $.each(reportsX , function( index, value ) {
                let randomColor = Math.floor(Math.random()*colors.length);
                newDataset[0].data.push(value);
                newDataset[0].backgroundColor.push(colors[randomColor]);
                labels.push(index);
            });
            // console.log(labels);
            // console.log(newDataset);
            // console.log(' aici specificFactors');
            // console.log(specificFactors);
            pieChart.data.labels = labels;
            pieChart.data.datasets = newDataset;
            pieChart.update();

            var textBar = ['NIVELURILE PARŢIALE DE RISC PE FACTORI DE RISC', 'Locul de muncă : ' + workspace + '', 'Nivel global de risc: '+generalPercent+''];
            var barChart = new Chartjs(document.getElementById("bar-chart"), {
                type: 'bar',
                data: {
                    datasets: [{ label: "Calcul Risc", backgroundColor: [], data: [], scaleOverride:true,
                        scaleSteps:9,
                        scaleStartValue:0,
                        scaleStepWidth:1, }],
                    labels: [],
                    tooltipText: ["Wild Quess", "Very Analytical", "Fine Prediction", "Bob's opinion", "Bob's opinion"],
                },
                options: {
                    legend: { display: false },
                    title: { display: true,  text: textBar },
                    responsive: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                steps: 1,
                                stepValue: 6,
                                max: 7 //max value for the chart is 60
                            }
                        }]
                    },
                    tooltips: {
                        enabled: true,
                        displayColors: false,
                        xAlign: 'center',
                        callbacks: {
                            title: function (tooltipItem, data) {
                                var title = data.tooltipText[tooltipItem[0].index];
                                return title;
                            },
                            // label: function (tooltipItem, data) {
                            //     return tooltipItem.xLabel + ' pages';
                            // }
                        },
                    }
                }
            });
            let newDatasetBar = [{ backgroundColor: [],  data: [] }];
            let labelsBar = [];
            let masuraBar = [];

            let specificFactorsSize = 0;
            $.each(specificFactors , function( index, value ) {
                specificFactorsSize += 1;
                // console.log('index: ' + index);
                // console.log('value: ' + value);
                let calc = value.calc;
                // console.log("calc: " + calc);
                // console.log(calc == 1);
                // let randomColor = Math.floor(Math.random()*colors.length);
                if(calc == 1) {
                    newDatasetBar[0].backgroundColor.push('#3e95cd');
                }else if(calc == 2){
                    newDatasetBar[0].backgroundColor.push('#FFFF00');
                }else if(calc == 3){
                    newDatasetBar[0].backgroundColor.push('#008000');
                }else if(calc > 3){
                    newDatasetBar[0].backgroundColor.push('#FF0000');
                    // }else{
                    //     newDatasetBar[0].backgroundColor.push(colors[randomColor]);
                }
                newDatasetBar[0].data.push(calc);

                labelsBar.push('F' + specificFactorsSize);
                masuraBar.push( value.masure);
            });
            // console.log(labelsBar);
            // console.log(masuraBar);
            // console.log(newDatasetBar);
            barChart.data.labels = labelsBar;
            barChart.data.tooltipText = masuraBar;
            barChart.data.datasets = newDatasetBar;

            barChart.update();

            let mathCalc = '';
            let mathCalcSub = '';
            let mathResults = [];
            let mathResultsSub = [];
            indexOut = 0;
            $.each(detailsRiskCalc , function( index, value ) {
                indexOut += 1;
                mathCalc += ''+value+' * ('+index+' * ' + index + ')';
                mathCalcSub += '('+value+' * '+index+')';
                mathResults.push(value * (index * index));
                mathResultsSub.push(value * index);
                if (indexOut < Object.keys(detailsRiskCalc).length) {
                    mathCalc += ' + ';
                    mathCalcSub += ' + ';
                }
            });


            let finalResult = mathCalc + '  \\over ' + mathCalcSub;
            var finalResultSub = 0;
            var finalResultSubSub = 0;
            for (var i = 0; i < mathResults.length; i++) {
                finalResultSub += mathResults[i] << 0;
            }
            for (var i = 0; i < mathResultsSub.length; i++) {
                finalResultSubSub += mathResultsSub[i] << 0;
            }

            let finalResultNext = finalResultSub + '  \\over ' + finalResultSubSub;
            let finalResultEnd = finalResultSub / finalResultSubSub;

            // console.log('finalResultSub');
            // console.log(finalResultSub);
            // console.log(finalResultSubSub);

            // var dom = document.getElementById("math-tex");
            // console.log(specificFactorsSize);
            var formula = "$$N_{rg} =  {\\sum_{i=1}^{"+specificFactorsSize+"}r_i * R_i \\over \\sum_{i=1}^{"+specificFactorsSize+"}r_i} = { " + finalResult +" } = { " + finalResultNext + "} = {"+finalResultEnd.toFixed(2)+"} $$";
            // console.log(formula);
            // console.log(formula);
            // setTimeout(function(){
            //     tex2img(formula, function (output) {
            //         console.log("text2img");
            //         console.log(output);
            //         file_namePie = "formula_per_"+$("[name='config_workspace']").find(':selected').html(); //created with userId and date
            //         //send it to server
            //         $.ajax({
            //             method: "POST",
            //             url: "/admin/print/save",
            //             data: {
            //                 data: output,
            //                 file_name: file_namePie,
            //                 company: $("[name='config_partner']").find(':selected').val(),
            //                 _token: $('meta[name="csrf-token"]').attr("content"),
            //             }
            //         });
            //     });
            // }, 3000);

            let company = $("[name='config_partner']").find(':selected').val();
            MathJax.Hub.Queue(function () {
                var wrapper = MathJax.HTML.Element("span", {}, formula);
                // console.log('wrapper');
                // console.log(wrapper);
                var output = { svg: "", img: ""};
                MathJax.Hub.Typeset(wrapper, function () {
                    var mjOut = wrapper.getElementsByTagName("svg")[0];
                    // console.log('mjOut');
                    // console.log(mjOut);
                    mjOut.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                    // thanks, https://spin.atomicobject.com/2014/01/21/convert-svg-to-png/
                    output.svg = mjOut.outerHTML;
                    // console.log(mjOut.outerHTML);
                    // console.info("dupa");
                    var image = new Image();
                    image.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(output.svg)));
                    image.onload = function() {
                        var canvas = document.createElement('canvas');
                        canvas.width = image.width;
                        canvas.height = image.height;
                        var context = canvas.getContext('2d');
                        context.drawImage(image, 0, 0);
                        output.img = canvas.toDataURL('image/png');
                        file_namePie = "formula_per_"+$("[name='config_workspace']").find(':selected').html(); //created with userId and date
                        //send it to server
                        $.ajax({
                            method: "POST",
                            url: "/admin/print/save",
                            data: {
                                data: output.img,
                                file_name: file_namePie,
                                workspace: workspace,
                                company: company,
                                _token: $('meta[name="csrf-token"]').attr("content"),
                            }
                        });
                    };

                });
            })
        }

        // console.log('pondere factorilor de risc');
        // console.log(reportsX);


        //{{ route('admin.documents.createEvaluare', [$partner->id]) }}

    }else{
        //Reset fields
        $("#work_process").val('');
        $("#exec").val('');
        $("#job").val('');
        $("#production_details").val('');
        $("#environment").val('');
        $("#evaluatori input").each(function (index, val) { $(val).val(''); });
        $("#echip_tehn input").each(function (index, val) { $(val).val(''); });

        $("input[name='config_timp_expunere']").val('');
        $("input[name='config_nr_loc_de_munca']").val('');

        $('#sbTwo tbody').empty();
    }
});


//Add or Edit a Company
$(document).on('click', '.addEditWorkStation', function (e) {
    e.preventDefault();
    // console.log($(this).hasClass('editWorkStation'));
    var edit = $(this).hasClass('editWorkStation');
    var deleteStation = $(this).hasClass('deleteWorkStation');
    var name = $("#workstation_name").val();
    var oldName = $("#workstation_name").data('oldname');
    var partnerId = $("#partner_cui").val();
    $(".se-pre-con").fadeIn("slow");

    $.post('/admin/workstation/save', {name: name, partnerId:partnerId, oldName:oldName, deleteStation:deleteStation}, function(response) {
        var workStationptions = '';
        if(response.success == true){
            // $("#workstation_name").attr('data-oldname','').removeAttr('data-oldname');
            $("#workstation_name").data('oldname', '');
            // $("#workplace_down").empty();
            workstations = response.data;
            $.each(workstations, function( index, value ) {
                workStationptions += '<option value="'+value.replace('"', '&quot;')+'">'+value+'</option>';
            });
            $("#workstation").html(workStationptions);
        }else{
            alert("Eruare!");
        }
        $(".se-pre-con").fadeOut("slow");
    });
});

$(document).on('click', '#copy_content', function (e) {
    let table = $('#sbTwo tbody').html();
    var partnerId = $("#partner_cui").val();
    sessionStorage.setItem('copy', table);
    $("#paste_content").removeClass("disabled");
});
$(document).on('click', '#paste_content', function (e) {
    console.log('paste_content');
    let currentTable = $('#sbTwo tbody').html();
    let currentNames = [];

    //Save names into an array to be checked
    let currentTableIndex = 0;
    $(currentTable).each(function(ind, val) {
        currentNames.push($(val).find('textarea').val().trim());
        currentTableIndex = ind;
    });
    // console.log('count');
    // console.log(currentTableIndex);
    var partnerId = $("#partner_cui").val();
    let table = sessionStorage.getItem('copy');
    var tableRemovedDuplicates = [ ];


    //Remove duplicate values
    $(table).each(function(index, val) {
        let name = $(val).find('textarea').val().trim();
        let currentIndex = $(val).find('.hidden').attr('name');
        let number = currentTableIndex === 0 ? (parseInt(currentTableIndex) + parseInt(index)) : ((parseInt(currentTableIndex) - 1) + parseInt(index))
        $(val).find('.hidden').attr('name', 'risks_codrisk_' + number);
        $(val).find('textarea').attr('name', 'risks_masure_' + number);
        $(val).find('.gravity').attr('name', 'risks_gravity_' + number);
        $(val).find('.probability').attr('name', 'risks_probability_' + number);
        $(val).find('.risk_result').attr('name', 'risks_calc_' + number);
        // console.log(name);
        // console.log(index);
        // console.log(currentIndex);
        // console.log(((parseInt(currentTableIndex) - 1) + parseInt(index)));

        if($.inArray(name, currentNames) == -1){
            tableRemovedDuplicates.push(val);
        }
    });

    $('#sbTwo tbody').append(tableRemovedDuplicates);
    // console.log(table);
    sessionStorage.clear('copy');
    $("#paste_content").addClass("disabled");
});
$(document).on('click', '#home-tab', function (e) {
    if(!$('#workspace :selected').val()){
        alert("Te rugam sa selectezi Locul de munca");
    }
});

//Save new Version Number
$(document).on('click', '#submit', function (e) {
        $("#confirm-version-before-submit").modal('hide');
        $(".modal-backdrop").removeClass("in");
        $("body").removeClass("modal-open");

        var version  = $("[name='versionnumber']").val();
        var dateVersion  = $("[name='versiondate']").val();
        $(".generate_evaluare").attr("href", $(".generate_evaluare").attr("href") + "/" + version + "/" + dateVersion);
});

//Delete a Risk from the list
$(document).on('click', '.fa.fa-trash-alt', function (e) {
    $(".se-pre-con").fadeIn("slow");
    let textarea = $(this).closest('td').find('textarea').val();
    let workstation = $("#workstation_name").val();
    let partnerId = $("[name='config_partner']").find(':selected').val();
    let workspace = $("[name='config_workspace']").find(':selected').val();
    let self =  $(this);

    // console.log(textarea);
    $.post('/admin/workstation/delete', { workstation: workstation, workspace: workspace, risk: textarea, partnerId:partnerId}, function(response) {

        if(response.success == true){
            self.closest("tr").remove();
        }else{
            alert("Eruare!");
        }

        $(".se-pre-con").fadeOut("slow");
    });
});
