(function() {

})();

$(document).on('click', '#notiDropdown', function (e) {
    console.log("dau clickk aici");
    return;

    $.post('/admin/workstation/save', {name: name, partnerId:partnerId, oldName:oldName, deleteStation:deleteStation}, function(response) {
        var workStationptions = '';
        if(response.success == true){
            // $("#workstation_name").attr('data-oldname','').removeAttr('data-oldname');
            $("#workstation_name").data('oldname', '');
            $("#workplace_down").empty();
            workstations = response.data;
            $.each(workstations, function( index, value ) {
                workStationptions += '<option value="'+value+'">'+value+'</option>';
            });
            $("#workstation").html(workStationptions);
        }else{
            alert("Eruare!");
        }
        $(".se-pre-con").fadeOut("slow");
    });
});
