
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.moment = require('moment');
window.tempusdominus = require('tempusdominus-bootstrap-4');
// window.c3 = require('c3');
// window.DataTable = require('datatables.net');
window.Noty = require('noty');
window.Select2 = require('select2');
window.Chartjs = require('chart.js');
window.DataTable = require('datatables.net');
// window.DataTable = require('datatables.net-bs4');

const PerfectScrollbar = require('perfect-scrollbar');

// Import datepicker
import 'bootstrap-datepicker';

$.ajaxSetup({
    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
});


//Include JS for risks
require("./_partials/risks.js");
require("./_partials/risk_management.js");
require("./_partials/admin.js");
require("./_partials/_user_testing.js");



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });
